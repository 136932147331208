<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <ValidationObserver ref="form"  v-slot="{ handleSubmit }">
              <b-form  @submit.prevent="handleSubmit(register)" >
              <b-row>
                <b-col lg="6" sm="12">
                  <ValidationProvider name="Organization" vid="org_id" rules="required|min_value:1">
                    <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="organization"
                      slot-scope="{ valid, errors }"
                      >
                      <template v-slot:label>
                      {{ $t('org_pro.organization') }} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        plain
                        v-model="payment.org_id"
                        :options="organizationList"
                        @change="check($event, 1)"
                        id="org_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                        >
                        <template v-slot:first>
                          <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                  <ValidationProvider v-if="paymentType" name="Payment Type" vid="payment_type_id">
                    <b-form-group
                      class="row"
                      label-cols-sm="4"
                      :label="$t('irrigation_config.payment_type')"
                      label-for="payment_type_id"
                    >
                      <b-form-select
                        plain
                        v-model="payment.payment_type_id"
                        :options="paymentTypes"
                        @change="check($event, 3)"
                        id="payment_type_id"
                        >
                        <template v-slot:first>
                          <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                    </b-form-group>
                  </ValidationProvider>
                  <ValidationProvider v-if="participationCategory" name="Participation Category" vid="participation_category_id">
                    <b-form-group
                      class="row"
                      label-cols-sm="4"
                      :label="$t('irrigation_config.participation_category')"
                      label-for="participation_category_id"
                    >
                      <b-form-select
                        plain
                        v-model="payment.participation_category_id"
                        :options="participationCategoryList"
                        id="participation_category_id"
                        >
                        <template v-slot:first>
                          <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                    </b-form-group>
                  </ValidationProvider>
                  <ValidationProvider v-if="discharge" name="Discharge(Cusec)" vid="discharge_cusec">
                    <b-form-group
                      class="row"
                      label-cols-sm="4"
                      :label="$t('irrigation_config.discharge_cusec')"
                      label-for="discharge_cusec"
                    >
                      <b-form-input
                        id="discharge_cusec"
                        v-model="payment.discharge_cusec"
                        placeholder=""
                      ></b-form-input>
                    </b-form-group>
                  </ValidationProvider>
                  <ValidationProvider v-if="gender" name="Gender" vid="gender">
                    <b-form-group
                      class="row"
                      label-cols-sm="4"
                      :label="$t('irrigation_config.gender')"
                      label-for="gender"
                    >
                      <b-form-select
                        plain
                        v-model="payment.gender"
                        :options="genders"
                        id="gender"
                        >
                        <template v-slot:first>
                          <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                    </b-form-group>
                  </ValidationProvider>
                  <ValidationProvider name="Effective From" vid="effective_from" rules="required">
                    <b-form-group
                      class="row inv-date"
                      label-cols-sm="4"
                      label-for="effective_from"
                      slot-scope="{ valid, errors }"
                    >
                      <template v-slot:label>
                      {{ $t('irrigation_config.effective_from') }} <span class="text-danger">*</span>
                      </template>
                      <flat-pickr class="form-control"
                          v-model="payment.effective_from"
                          placeholder="Select Date"
                          :state="errors[0] ? false : (valid ? true : null)"
                      ></flat-pickr>
                          <div class="invalid-feedback">
                              {{ errors[0] }}
                          </div>
                      </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col lg="6" sm="12">
                  <ValidationProvider name="Application Type" vid="application_type_id" rules="required|min_value:1">
                    <b-form-group
                      class="row"
                      label-cols-sm="4"
                      :label="$t('irrigation_config.application_type')"
                      label-for="application_type_id"
                    >
                      <b-form-select
                        plain
                        v-model="payment.application_type_id"
                        :options="applicationTypes"
                        @change="check($event, 2)"
                        id="application_type_id"
                        >
                        <template v-slot:first>
                          <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                    </b-form-group>
                  </ValidationProvider>
                  <ValidationProvider v-if="schemeType" name="Scheme Type" vid="scheme_type_id">
                    <b-form-group
                      class="row"
                      label-cols-sm="4"
                      :label="$t('irrigation_config.scheme_type')"
                      label-for="scheme_type_id"
                    >
                      <b-form-select
                        plain
                        v-model="payment.scheme_type_id"
                        :options="schemeTypeList"
                        id="scheme_type_id"
                        >
                        <template v-slot:first>
                          <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                    </b-form-group>
                  </ValidationProvider>
                  <ValidationProvider v-if="pumpType" name="Pump Type" vid="pump_type_id">
                    <b-form-group
                      class="row"
                      label-cols-sm="4"
                      :label="$t('irrigation_config.pump_type')"
                      label-for="pump_type_id"
                    >
                      <b-form-select
                        plain
                        v-model="payment.pump_type_id"
                        :options="pumpTypeList"
                        id="pump_type_id"
                        >
                        <template v-slot:first>
                          <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                    </b-form-group>
                  </ValidationProvider>
                  <ValidationProvider v-if="circleArea" name="Circle Area" vid="circle_area_id">
                    <b-form-group
                      class="row"
                      label-cols-sm="4"
                      :label="$t('irrigation_config.circle_area')"
                      label-for="circle_area_id"
                    >
                      <b-form-select
                        plain
                        v-model="payment.circle_area_id"
                        :options="circleAreaList"
                        id="circle_area_id"
                        >
                        <template v-slot:first>
                          <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                    </b-form-group>
                  </ValidationProvider>
                  <ValidationProvider v-if="water_test" name="Testing Parameter" vid="testing_parameter" rules="required">
                        <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="testing_parameter"
                        >
                          <template v-slot:label>
                          {{ $t('irrigation_config.testing_parameter') }} <span class="text-danger">*</span>
                          </template>
                        <b-form-select
                            plain
                            v-model="payment.testing_parameter_id"
                            :options="waterTestingParams"
                            id="testing_parameter"
                        >
                        <template v-slot:first>
                            <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                    </b-form-select>
                    </b-form-group>
                  </ValidationProvider>
                  <ValidationProvider name="Amount" vid="amount" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="amount"
                            slot-scope="{ valid, errors }"
                        >
                            <template v-slot:label>
                                {{ $t('irrigation_config.amount') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                                id="amount"
                                type="number"
                                v-model="payment.amount"
                                :state="errors[0] ? false : (valid ? true : null)"
                                placeholder=""
                            ></b-form-input>
                            <div class="invalid-feedback">
                                {{ errors[0] }}
                            </div>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
              </b-row>
                <div class="row">
                  <div class="col-sm-3"></div>
                  <div class="col text-right">
                    <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                    &nbsp;
                    <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-1')">{{ $t('globalTrans.cancel') }}</b-button>
                  </div>
                </div>
              </b-form>
            </ValidationObserver>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<style>
.inv-date .invalid-feedback {
  display: block !important;
}
</style>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { irriSchemeServiceBaseUrl } from '../../../../../config/api_config'
import { masterPaymentStore, masterPaymentUpdate } from '../../api/routes'

export default {
    name: 'PaymentEntryForm',
    props: ['id'],
    components: {
    ValidationObserver,
    ValidationProvider
    },
    data () {
        return {
            bmda_id: 15,
            water_testing_type: 4,
            water_test: false,
            saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
            payment: {
                org_id: 0,
                application_type_id: 0,
                payment_type_id: 0,
                scheme_type_id: 0,
                participation_category_id: 0,
                pump_type_id: 0,
                discharge_cusec: '',
                circle_area_id: 0,
                gender: 0,
                amount: '',
                effective_from: '',
                testing_parameter_id: 0
            },
            applicationTypes: [],
            paymentTypes: [],
            participationCategoryList: [
                { value: 1, text: 'Deep Tubewell' },
                { value: 2, text: 'Irrigation Drain' },
                { value: 3, text: 'Electrification' },
                { value: 4, text: 'Solar' }
            ],
            genders: [
                { value: 1, text: 'Male' },
                { value: 2, text: 'Female' }
            ],
            paymentType: false,
            schemeType: false,
            participationCategory: false,
            pumpType: false,
            discharge: false,
            circleArea: false,
            gender: false,
            waterTestingParams: []
        }
    },
    watch: {
        'payment.org_id': function (newVal, oldVal) {
          this.checkTestingParam(newVal, oldVal)
        },
        'payment.application_type_id': function (newVal, oldVal) {
          this.checkTestingParam(newVal, oldVal)
        },
        'payment.payment_type_id': function (newVal, oldVal) {
          this.filterWaterTestingParam(newVal, oldVal)
        }
    },
    created () {
      this.applicationTypeList()
      if (this.id) {
        const tmp = this.getpaymentData()
        this.payment = tmp
        if (this.payment.payment_type_id !== 0) {
          if (this.payment.org_id === 3 && this.payment.application_type_id === 1) {
            this.schemeBADCPaymentTypeList()
          } else if (this.payment.org_id === 15 && this.payment.application_type_id === 1) {
            this.schemeBMDAPaymentTypeList()
          } else if (this.payment.application_type_id === 2) {
            this.pumpOperationPaymentTypeList()
          } else if (this.payment.application_type_id === 3) {
            this.smartCardPaymentTypeList()
          } else if (this.payment.application_type_id === 4) {
            this.waterTestingPaymentTypeList()
          }
          this.paymentType = true
        }
        if (this.payment.scheme_type_id !== 0) {
          this.schemeType = true
        }
        if (this.payment.participation_category_id !== 0) {
          this.participationCategory = true
        }
        if (this.payment.pump_type_id !== 0) {
          this.pumpType = true
        }
        if (this.payment.discharge_cusec) {
          this.discharge = true
        }
        if (this.payment.circle_area_id !== 0) {
          this.circleArea = true
        }
        if (this.payment.gender !== 0) {
          this.gender = true
        }
      }
    },
    mounted () {
        core.index()
    },
    computed: {
        organizationList: function () {
          return this.$store.state.commonObj.organizationProfileList
        },
        schemeTypeList: function () {
          return this.$store.state.IrriConfig.commonObj.schemeTypeList.filter(item => item.status === 0)
        },
        pumpTypeList: function () {
          return this.$store.state.IrriConfig.commonObj.pumpType.filter(item => item.status === 0)
        },
        circleAreaList: function () {
          // return this.$store.state.IrriConfig.commonObj.circleArea.filter(item => (item.status === 0))
          return this.$store.state.IrriConfig.commonObj.circleArea
        }
    },
    methods: {
        checkTestingParam (newVal, oldVal) {
          if (newVal !== oldVal) {
            this.water_test = this.payment.application_type_id === this.water_testing_type
          }
        },
        filterWaterTestingParam (newVal, oldVal) {
          if (newVal !== oldVal) {
            const orgId = this.payment.org_id
            const paymentTypeId = this.payment.payment_type_id
            const waterTestingParameterList = this.$store.state.IrriConfig.commonObj.waterTestingParameterList
            this.waterTestingParams = waterTestingParameterList.filter(param => param.org_id === orgId && param.testing_type_id === paymentTypeId)
          }
        },
        getpaymentData () {
          const tmpData = this.$store.state.list.find(item => item.id === this.id)
          return JSON.parse(JSON.stringify(tmpData))
        },
        async register () {
          this.$store.dispatch('mutateCommonProperties', { loading: true })
          let result = null
          const loadinState = { loading: false, listReload: false }
          if (this.payment.id) {
            result = await RestApi.putData(irriSchemeServiceBaseUrl, `${masterPaymentUpdate}/${this.id}`, this.payment)
          } else {
            result = await RestApi.postData(irriSchemeServiceBaseUrl, masterPaymentStore, this.payment)
          }
          loadinState.listReload = true
          this.$store.dispatch('mutateCommonProperties', loadinState)
          if (result.success) {
            this.$toast.success({
              title: 'Success',
              message: this.id ? 'Data updated successfully' : 'Data save successfully',
              color: '#D6E09B'
            })
            this.$bvModal.hide('modal-1')
          } else {
            this.$refs.form.setErrors(result.errors)
            this.$toast.error({
              title: 'Error',
              message: 'Operation failed! Please, try again.'
            })
          }
        },
        check (event, type) {
          const orgId = this.payment.org_id
          const applicationTypeId = this.payment.application_type_id
          const paymentTypeId = this.payment.payment_type_id
          // const schemeTypeId = this.payment.scheme_type_id
          // const participationCategoryId = this.payment.participation_category_id
          // const pumpTypeId = this.payment.pump_type_id
          // const circleAreaId = this.payment.circle_area_id
          // const gender = this.payment.gender
          if (orgId === 3 && applicationTypeId === 1) { // org_id 3 = BADC & application type is 2 = Scheme
            if (type === 2) {
              this.payment.payment_type_id = 0
            }
            this.schemeBADCPaymentTypeList()
            this.paymentType = true
            this.schemeType = false
            this.participationCategory = false
            this.discharge = false
            this.pumpType = false
            this.circleArea = false
            this.gender = false
            if (paymentTypeId === 1) {
              this.schemeType = true
              this.paymentType = true
              this.participationCategory = false
              this.discharge = false
              this.pumpType = false
              this.circleArea = false
              this.gender = false
            } else if (paymentTypeId === 3) {
              this.paymentType = true
              this.pumpType = true
              this.schemeType = false
              this.participationCategory = false
              this.discharge = true
              this.circleArea = false
              this.gender = false
            } else if (paymentTypeId === 2 || paymentTypeId === 4) {
              this.paymentType = true
              this.discharge = true
              this.pumpType = false
              this.participationCategory = true
              this.schemeType = false
              this.circleArea = false
              this.gender = false
            } else {
              // this.paymentType = false
              this.schemeType = false
              this.participationCategory = false
              this.pumpType = false
              this.discharge = false
              this.circleArea = false
              this.gender = false
            }
          } else if (orgId === 15 && applicationTypeId === 1) { // org_id 15 = BMDA & application type is 2 = Scheme
            if (type === 2) {
              this.payment.payment_type_id = 0
            }
            this.schemeBMDAPaymentTypeList()
            this.paymentType = true
            this.schemeType = false
            this.participationCategory = false
            this.circleArea = false
            this.pumpType = false
            this.discharge = false
            this.gender = false
            if (paymentTypeId === 1) {
              this.paymentType = true
              this.schemeType = true
              this.participationCategory = false
              this.circleArea = false
              this.pumpType = false
              this.discharge = false
              this.gender = false
            } else if (paymentTypeId === 2 || paymentTypeId === 4) {
              this.paymentType = true
              this.circleArea = true
              this.schemeType = false
              this.participationCategory = false
              this.pumpType = false
              this.discharge = false
              this.gender = false
            } else {
              this.schemeType = false
              this.participationCategory = false
              this.circleArea = false
              // this.paymentType = false
              this.pumpType = false
              this.discharge = false
              this.gender = false
            }
          } else if (applicationTypeId === 2) { // application type is 2 = Pump Operator
            if (type === 2) {
              this.payment.payment_type_id = 0
            }
            this.pumpOperationPaymentTypeList()
            this.paymentType = true
            if (applicationTypeId === 2 && paymentTypeId === 3) {
              this.paymentType = true
              this.gender = true
              this.schemeType = false
              this.participationCategory = false
              this.circleArea = false
              this.pumpType = false
              this.discharge = false
            } else {
              this.gender = false
              this.schemeType = false
              this.participationCategory = false
              this.circleArea = false
              this.pumpType = false
              this.discharge = false
            }
          } else if (applicationTypeId === 3) { // application type is 3 = Smart Card
            if (type === 2) {
              this.payment.payment_type_id = 0
            }
            this.smartCardPaymentTypeList()
            this.paymentType = true
            this.gender = false
            this.schemeType = false
            this.participationCategory = false
            this.circleArea = false
            this.pumpType = false
            this.discharge = false
          } else if (applicationTypeId === 4) { // application type is 4 = Water Testing
            if (type === 2) {
              this.payment.payment_type_id = 0
            }
            this.waterTestingPaymentTypeList()
            this.paymentType = true
            this.gender = false
            this.schemeType = false
            this.participationCategory = false
            this.circleArea = false
            this.pumpType = false
            this.discharge = false
          } else {
            this.paymentType = false
            this.schemeType = false
            this.participationCategory = false
            this.pumpType = false
            this.discharge = false
            this.circleArea = false
            this.gender = false
          }
        },
        applicationTypeList () {
          const objectData = this.$store.state.commonObj.paymentApplicationTypeList
          this.applicationTypes = objectData.map(item => {
            if (this.$i18n.locale === 'bn') {
              return { value: item.id, text: item.name_bn }
            } else {
              return { value: item.id, text: item.name }
            }
          })
        },
        schemeBADCPaymentTypeList () {
          const objectData = this.$store.state.commonObj.paymentSchemeBADCPaymentTypeList
          this.paymentTypes = objectData.map(item => {
            if (this.$i18n.locale === 'bn') {
              return { value: item.id, text: item.name_bn }
            } else {
              return { value: item.id, text: item.name }
            }
          })
        },
        schemeBMDAPaymentTypeList () {
          const objectData = this.$store.state.commonObj.paymentSchemeBMDAPaymentTypeList
          this.paymentTypes = objectData.map(item => {
            if (this.$i18n.locale === 'bn') {
              return { value: item.id, text: item.name_bn }
            } else {
              return { value: item.id, text: item.name }
            }
          })
        },
        pumpOperationPaymentTypeList () {
          const objectData = this.$store.state.commonObj.paymetPumpOperationPaymentTypeList
          this.paymentTypes = objectData.map(item => {
            if (this.$i18n.locale === 'bn') {
              return { value: item.id, text: item.name_bn }
            } else {
              return { value: item.id, text: item.name }
            }
          })
        },
        smartCardPaymentTypeList () {
          const objectData = this.$store.state.commonObj.paymetSmartCardPaymentTypeList
          this.paymentTypes = objectData.map(item => {
            if (this.$i18n.locale === 'bn') {
              return { value: item.id, text: item.name_bn }
            } else {
              return { value: item.id, text: item.name }
            }
          })
        },
        waterTestingPaymentTypeList () {
          const objectData = this.$store.state.commonObj.paymetWaterTestingPaymentTypeList
          this.paymentTypes = objectData.map(item => {
            if (this.$i18n.locale === 'bn') {
              return { value: item.id, text: item.name_bn }
            } else {
              return { value: item.id, text: item.name }
            }
          })
        }
    }
}
</script>
