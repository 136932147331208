<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('irrigation_config.payment') }}</h4>
      </template>
      <template v-slot:body>
        <b-row>
          <b-col lg="6" md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="4"
              :label="$t('globalTrans.organization')"
              label-for="org_id"
            >
              <b-form-select
                plain
                v-model="search.org_id"
                :options="orgList"
                id="org_id"
                @change="check($event, 1)"
                >
                <template v-slot:first>
                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col lg="6" md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="4"
              :label="$t('irrigation_config.application_type')"
              label-for="application_type"
            >
              <b-form-select
                plain
                v-model="search.application_type_id"
                @change="check($event, 2)"
                :options="applicationTypes"
                id="application_type"
                >
                <template v-slot:first>
                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col v-if="paymentType" lg="6" md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="4"
              :label="$t('irrigation_config.payment_type')"
              label-for="payment_type"
            >
              <b-form-select
                plain
                v-model="search.payment_type_id"
                :options="paymentTypes"
                @change="check($event, 3)"
                id="payment_type"
                >
                <template v-slot:first>
                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col  v-if="participationCategory" lg="6" md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="4"
              :label="$t('irrigation_config.participation_category')"
              label-for="payment_type_id"
            >
              <b-form-select
                plain
                v-model="search.participation_category_id"
                :options="participationCategoryList"
                id="participation_category"
                >
                <template v-slot:first>
                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col class="text-right">
              <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
          </b-col>
        </b-row>
      </template>
    </iq-card>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('irrigation_config.payment_list') }}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button class="btn_add_new" v-b-modal.modal-1 @click="resetId">
              <i class="fas fa-plus"></i>{{  $t('globalTrans.add_new') }}
            </b-button>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table thead-class="table_head" bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(organization)="data">
                      {{ data.item.organization }}
                    </template>
                    <template v-slot:cell(application_type)="data">
                      {{ data.item.application_type }}
                    </template>
                    <template v-slot:cell(payment_type)="data">
                      {{ data.item.payment_type }}
                    </template>
                    <template v-slot:cell(participation_category)="data">
                      {{ data.item.participation_category }}
                    </template>
                    <template v-slot:cell(discharge_cusec)="data">
                      {{ data.item.discharge_cusec }}
                    </template>
                    <template v-slot:cell(amount)="data">
                      {{ $n(data.item.amount) }}
                    </template>
                    <template v-slot:cell(effective_from)="data">
                      {{ data.item.effective_from | dateFormat }}
                    </template>
                    <template v-slot:cell(status)="data">
                      <!-- {{ data.item.status ? $t('globalTrans.inactive') : $t('globalTrans.active') }} -->
                       <span class="badge badge-danger" v-if="data.item.status">{{ $t('globalTrans.inactive') }}</span>
                        <span class="badge badge-success" v-else>{{ $t('globalTrans.active') }}</span>
                    </template>
                    <template v-slot:cell(action)="data">
                      <a href="javascript:" class="btn_table_action table_action_edit" title="Edit" v-b-modal.modal-4 @click="edit(data.item)"><i class="ri-ball-pen-fill"></i></a>
                      <a href="javascript:" class="btn_table_action table_action_status" title="Active" v-if="data.item.status === 0" @click="remove(data.item)"><i class="fas fa-toggle-on"></i></a>
                      <a href="javascript:" class="btn_table_action table_action_toggle" title="Inactive" v-else @click="remove(data.item)"><i class="fas fa-toggle-off"></i></a>
                    </template>
                  </b-table>
                  <b-pagination
                    align="center"
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="searchData"
                    />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal id="modal-1" size="xl" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <p>
        <FormV :id="editItemId" :key="editItemId" />
      </p>
    </b-modal>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import Form from './Form'
import RestApi, { irriSchemeServiceBaseUrl } from '@/config/api_config'
import { masterPaymentList, masterPaymentToggleStatus } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import { mapGetters } from 'vuex'

export default {
  name: 'UiDataTable',
  mixins: [ModalBaseMasterList],
  components: {
    FormV: Form
  },
  data () {
    return {
      search: {
        org_id: 0,
        application_type_id: 0,
        payment_type_id: 0,
        participation_category_id: 0
      },
      id: 0,
      organizationList: [],
      paymentTypes: [],
      applicationTypes: [],
      participationCategoryList: [
        { value: 1, text: 'Deep Tubewell' },
        { value: 2, text: 'Irrigation Drain' },
        { value: 3, text: 'Electrification' },
        { value: 4, text: 'Solar' }
      ],
      genders: [
        { value: 1, text: 'Male' },
        { value: 2, text: 'Female' }
      ],
      paymentType: false,
      schemeType: false,
      participationCategory: false,
      pumpType: false,
      discharge: false,
      circleArea: false,
      gender: false
    }
  },
  computed: {
    ...mapGetters({
      authUser: 'Auth/authUser'
    }),
    formTitle () {
       return (this.editItemId === 0) ? this.$t('irrigation_config.payment_entry') : this.$t('irrigation_config.payment') + ' ' + this.$t('globalTrans.modify')
    },
    columns () {
      const labels = [
        { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
        { label: this.$t('irrigation_config.organization'), class: 'text-center' },
        { label: this.$t('irrigation_config.application_type'), class: 'text-center' },
        { label: this.$t('irrigation_config.payment_type'), class: 'text-center' },
        { label: this.$t('irrigation_config.participation_category'), class: 'text-center' },
        { label: this.$t('irrigation_config.discharge_cusec'), class: 'text-center' },
        { label: this.$t('irrigation_config.amount'), class: 'text-center' },
        { label: this.$t('irrigation_config.effective_from'), class: 'text-center' },
        { label: this.$t('globalTrans.status'), class: 'text-center' },
        { label: this.$t('globalTrans.action'), class: 'text-center w-10' }
      ]

      let keys = []

      keys = [
        { key: 'index' },
        { key: 'organization' },
        { key: 'application_type' },
        { key: 'payment_type' },
        { key: 'participation_category' },
        { key: 'discharge_cusec' },
        { key: 'amount' },
        { key: 'effective_from' },
        { key: 'status' },
        { key: 'action' }
      ]
      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
      })
    },
    orgList: function () {
      return this.$store.state.commonObj.organizationProfileList
    },
    SchemeTypeList: function () {
      return this.$store.state.IrriConfig.commonObj.schemeTypeList.filter(item => item.status === 0)
    }
  },
  watch: {
    loadingState: function (newVal, oldVal) {
      if (newVal) {
        this.loadData()
      }
    }
  },
  created () {
    this.applicationTypeList()
  },
  mounted () {
    core.index()
    if (this.authUser.role_id === 0 && this.authUser.is_org_admin === 1) {
      this.search = Object.assign({}, this.search, {
        org_id: this.authUser.org_id
      })
    } else if (this.authUser.role_id === 0) {
      this.search = Object.assign({}, this.search, {
        org_id: this.authUser.office_detail.org_id
      })
    }
    this.loadData()
  },
  methods: {
    searchData () {
      if (this.authUser.role_id === 0 && this.authUser.is_org_admin === 1) {
        this.search = Object.assign({}, this.search, {
          org_id: this.authUser.org_id
        })
      } else if (this.authUser.role_id === 0) {
        this.search = Object.assign({}, this.search, {
          org_id: this.authUser.office_detail.org_id
        })
      }
      this.loadData()
    },
    remove (item) {
      this.changeStatus(irriSchemeServiceBaseUrl, masterPaymentToggleStatus, item)
    },
    loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(irriSchemeServiceBaseUrl, masterPaymentList, params).then(response => {
        if (response.success) {
          this.$store.dispatch('setList', this.dataList(response.data.data))
          this.paginationData(response.data)
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    dataList (data) {
      const orgList = this.$store.state.orgList
      const applicationTypeList = this.$store.state.commonObj.paymentApplicationTypeList
      const schemeBADCPaymentTypeList = this.$store.state.commonObj.paymentSchemeBADCPaymentTypeList
      const schemeBMDAPaymentTypeList = this.$store.state.commonObj.paymentSchemeBMDAPaymentTypeList
      const pumpOperationPaymentTypeList = this.$store.state.commonObj.paymetPumpOperationPaymentTypeList
      const smartCardPaymentTypeList = this.$store.state.commonObj.paymetSmartCardPaymentTypeList
      const waterTestingPaymentTypeList = this.$store.state.commonObj.paymetWaterTestingPaymentTypeList
      let tmpData = {}
      let paymentTypeData = {}
      const listData = data.map(item => {
        tmpData = orgList.find(org => org.value === item.org_id)
        const orgData = { organization: this.$i18n.locale === 'bn' ? tmpData.text_bn : tmpData.text_en }
        tmpData = applicationTypeList.find(applicationType => applicationType.id === item.application_type_id)
        const appData = { application_type: this.$i18n.locale === 'bn' ? tmpData.name_bn : tmpData.name }
        if (item.org_id === 3 && item.application_type_id === 1) {
          tmpData = schemeBADCPaymentTypeList.find(paymentItem => paymentItem.id === item.payment_type_id)
          paymentTypeData = { payment_type: this.$i18n.locale === 'bn' ? tmpData.name_bn : tmpData.name }
        } else if (item.org_id === 15 && item.application_type_id === 1) {
          tmpData = schemeBMDAPaymentTypeList.find(paymentItem => paymentItem.id === item.payment_type_id)
          paymentTypeData = { payment_type: this.$i18n.locale === 'bn' ? tmpData.name_bn : tmpData.name }
        }
        if (item.application_type_id === 2) {
          tmpData = pumpOperationPaymentTypeList.find(paymentItem => paymentItem.id === item.payment_type_id)
          paymentTypeData = { payment_type: this.$i18n.locale === 'bn' ? tmpData.name_bn : tmpData.name }
        } else if (item.application_type_id === 3) {
          tmpData = smartCardPaymentTypeList.find(paymentItem => paymentItem.id === item.payment_type_id)
          paymentTypeData = { payment_type: this.$i18n.locale === 'bn' ? tmpData.name_bn : tmpData.name }
        } else if (item.application_type_id === 4) {
          tmpData = waterTestingPaymentTypeList.find(paymentItem => paymentItem.id === item.payment_type_id)
          paymentTypeData = { payment_type: this.$i18n.locale === 'bn' ? tmpData.name_bn : tmpData.name }
        }
        tmpData = this.participationCategoryList.find(participationItem => participationItem.value === item.participation_category_id)
        const participationCategoryData = { participation_category: tmpData ? tmpData.text : '' }
        return Object.assign({}, item, orgData, appData, paymentTypeData, participationCategoryData)
      })
      return listData
    },
    check (event, type) {
      const orgId = this.search.org_id
      const applicationTypeId = this.search.application_type_id
      const paymentTypeId = this.search.payment_type_id
      if (orgId === 3 && applicationTypeId === 1) { // org_id 3 = BADC & application type is 2 = Scheme
        if (type === 2) {
          this.search.payment_type_id = 0
        }
        this.schemeBADCPaymentTypeList()
        this.paymentType = true
        this.schemeType = false
        this.participationCategory = false
        this.discharge = false
        this.pumpType = false
        this.circleArea = false
        this.gender = false
        if (paymentTypeId === 1) {
          this.schemeType = true
          this.paymentType = true
          this.participationCategory = false
          this.discharge = false
          this.pumpType = false
          this.circleArea = false
          this.gender = false
        } else if (paymentTypeId === 3) {
          this.paymentType = true
          this.pumpType = true
          this.schemeType = false
          this.participationCategory = false
          this.discharge = true
          this.circleArea = false
          this.gender = false
        } else if (paymentTypeId === 2 || paymentTypeId === 4) {
          this.paymentType = true
          this.discharge = true
          this.pumpType = false
          this.participationCategory = true
          this.schemeType = false
          this.circleArea = false
          this.gender = false
        } else {
          // this.paymentType = false
          this.schemeType = false
          this.participationCategory = false
          this.pumpType = false
          this.discharge = false
          this.circleArea = false
          this.gender = false
        }
      } else if (orgId === 15 && applicationTypeId === 1) { // org_id 15 = BMDA & application type is 2 = Scheme
        if (type === 2) {
          this.search.payment_type_id = 0
        }
        this.schemeBMDAPaymentTypeList()
        this.paymentType = true
        this.schemeType = false
        this.participationCategory = false
        this.circleArea = false
        this.pumpType = false
        this.discharge = false
        this.gender = false
        if (paymentTypeId === 1) {
          this.paymentType = true
          this.schemeType = true
          this.participationCategory = false
          this.circleArea = false
          this.pumpType = false
          this.discharge = false
          this.gender = false
        } else if (paymentTypeId === 2 || paymentTypeId === 4) {
          this.paymentType = true
          this.circleArea = true
          this.schemeType = false
          this.participationCategory = false
          this.pumpType = false
          this.discharge = false
          this.gender = false
        } else {
          this.schemeType = false
          this.participationCategory = false
          this.circleArea = false
          // this.paymentType = false
          this.pumpType = false
          this.discharge = false
          this.gender = false
        }
      } else if (applicationTypeId === 2) { // application type is 2 = Pump Operator
        if (type === 2) {
          this.search.payment_type_id = 0
        }
        this.pumpOperationPaymentTypeList()
        this.paymentType = true
        if (applicationTypeId === 2 && paymentTypeId === 3) {
          this.paymentType = true
          this.gender = true
          this.schemeType = false
          this.participationCategory = false
          this.circleArea = false
          this.pumpType = false
          this.discharge = false
        } else {
          this.gender = false
          this.schemeType = false
          this.participationCategory = false
          this.circleArea = false
          this.pumpType = false
          this.discharge = false
        }
      } else if (applicationTypeId === 3) { // application type is 3 = Smart Card
        if (type === 2) {
          this.search.payment_type_id = 0
        }
        this.smartCardPaymentTypeList()
        this.paymentType = true
        this.gender = false
        this.schemeType = false
        this.participationCategory = false
        this.circleArea = false
        this.pumpType = false
        this.discharge = false
      } else if (applicationTypeId === 4) { // application type is 4 = Water Testing
        if (type === 2) {
          this.search.payment_type_id = 0
        }
        this.waterTestingPaymentTypeList()
        this.paymentType = true
        this.gender = false
        this.schemeType = false
        this.participationCategory = false
        this.circleArea = false
        this.pumpType = false
        this.discharge = false
      } else {
        this.paymentType = false
        this.schemeType = false
        this.participationCategory = false
        this.pumpType = false
        this.discharge = false
        this.circleArea = false
        this.gender = false
      }
    },
    applicationTypeList () {
      const objectData = this.$store.state.commonObj.paymentApplicationTypeList
      this.applicationTypes = objectData.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item.id, text: item.name_bn }
        } else {
          return { value: item.id, text: item.name }
        }
      })
    },
    schemeBADCPaymentTypeList () {
      const objectData = this.$store.state.commonObj.paymentSchemeBADCPaymentTypeList
      this.paymentTypes = objectData.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item.id, text: item.name_bn }
        } else {
          return { value: item.id, text: item.name }
        }
      })
    },
    schemeBMDAPaymentTypeList () {
      const objectData = this.$store.state.commonObj.paymentSchemeBMDAPaymentTypeList
      this.paymentTypes = objectData.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item.id, text: item.name_bn }
        } else {
          return { value: item.id, text: item.name }
        }
      })
    },
    pumpOperationPaymentTypeList () {
      const objectData = this.$store.state.commonObj.paymetPumpOperationPaymentTypeList
      this.paymentTypes = objectData.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item.id, text: item.name_bn }
        } else {
          return { value: item.id, text: item.name }
        }
      })
    },
    smartCardPaymentTypeList () {
      const objectData = this.$store.state.commonObj.paymetSmartCardPaymentTypeList
      this.paymentTypes = objectData.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item.id, text: item.name_bn }
        } else {
          return { value: item.id, text: item.name }
        }
      })
    },
    waterTestingPaymentTypeList () {
      const objectData = this.$store.state.commonObj.paymetWaterTestingPaymentTypeList
      this.paymentTypes = objectData.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item.id, text: item.name_bn }
        } else {
          return { value: item.id, text: item.name }
        }
      })
    }
  }
}
</script>
